<template>
	<video :id="roomId" width="510" height="290" autoplay></video>
</template>
  
<script>

import { onMounted, reactive, toRefs } from 'vue'
import kurentoUtils from 'kurento-utils'
import io from 'socket.io-client'

export default {
	name: 'video-index',
	components: {},
	props: {
		droneName: {}
	},
	setup(props) {
		const state = reactive({
			// Local 변수
			droneName: '',
			remoteVideo: '',
			roomId: props.droneName == 'SYNC_FC01' ? 'KETI_Simul_13tdvI' : 'KETI_Simul_15vUA2',
			streamId: 'camera',

			// Kurento 변수
			wsKurento: '',
			webRtcPeer: ''
		})

		onMounted(() => {
			console.log(props.droneName, state.roomId, state.streamId)
			if (state.remoteVideo) return
			state.remoteVideo = document.getElementById(state.roomId)
			state.wsKurento = io('https://data.iotocean.org:7719')
			state.wsKurento.on('connect', () => {
				console.log('Connected to server')

				state.wsKurento.on('disconnect', () => {
					console.log('Disconnected from server')
					if (state.webRtcPeer) {
						state.webRtcPeer.dispose()
						state.webRtcPeer = null
					}
				})

				state.wsKurento.on('subscribed', ({ sdpAnswer }) => {
					console.log('Subscribed')
					state.webRtcPeer.processAnswer(sdpAnswer)
				})

				// 방 참여 오류 처리
				state.wsKurento.on('roomJoiningError', () => {
					console.error('Error joining the room')
				})

				state.wsKurento.on('subscribingError', () => {
					console.error('Error subscribing')
				})

				state.wsKurento.on('iceCandidate', ({ candidate }) => {
					state.webRtcPeer.addIceCandidate(candidate)
				})

				state.wsKurento.on('roomJoined', () => {
					// 방에 연결되면 subscribe 시작
					startView()
				})
			})

			// 소켓 연결 후, 생성되어 있는 방 입장
			joinRoom()

		})

		// Kurento Subscribe 관련 함수
		const joinRoom = () => {
			const videoId = `${state.streamId}_${state.roomId}`

			state.wsKurento.emit('joinRoom', videoId)
		}

		const startView = () => {
			try {
				const options = {
					remoteVideo: state.remoteVideo,
					onicecandidate: onIceCandidate
				}
				state.webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, error => {
					if (error) throw new Error(`start WebRtcPeerRecvonly error`, error)
					state.webRtcPeer.generateOffer(onOfferViewer)
				})
			} catch (error) {
				throw new Error(`start subscribe error`, error)
			}
		}

		const onIceCandidate = candidate => {
			const videoId = `${state.streamId}_${state.roomId}`
			const message = {
				roomId: videoId,
				current: 'viewer',
				candidate: candidate
			}
			console.log('socket emit onIceCandidate: ', message)
			state.wsKurento.emit('iceCandidate', message)
		}

		const onOfferViewer = (error, offerSdp) => {
			const videoId = `${state.streamId}_${state.roomId}`
			if (error) throw new Error(`Error generating SDP Offer:`, error)

			const message = { roomId: videoId, sdpOffer: offerSdp }
			state.wsKurento.emit('subscribe', message)
		}


		return {
			...toRefs(state)
		}
	}
}
</script>

<style lang="scss"></style>
  