<template>
  <div :class="[{'image-component' : modeMinimize === true }, {'image-component-ex' : modeMinimize === false }, {'image-component-none' : isHudShow === false }]">
    <div class="image-box" v-if="mainState.isRF">
      <div class="image-box-title" :style="isHudShow === true ? '' : 'opacity: 0'">
        <span style="height: 2.1rem; width: 100%; text-align: center; font-size: 1.6rem; color: white">촬영이미지</span>
      </div>
      <div style="height: calc(100% - 52px); margin-top: 0.5rem;  overflow-y: auto;">
        <table v-if="droneList.length == 0"
          style="width: 100%; height: 100%; margin-left:auto; margin-right:auto; background: rgba(58, 64, 68, 0.8); border-radius: 16px"
        >
          <div class="component-name-image">
            <div class="drone-name-box-image">
              <div class="drone-name-image">대상 기체를 선택해 주세요.</div>
            </div>
          </div>
        </table>
        <table v-else-if="droneList.length == 1 && modeMinimize || !isHudShow" style="width: 100%; margin-left:auto; margin-right:auto;">
          <tr>
            <image-panel-td :droneIdx="0" :isHudShow="isHudShow" :imgHeight="bigImgHeight" />
          </tr>
        </table>

        <table v-else-if="droneList.length > 1 && modeMinimize" style="width: 100%; margin-left:auto; margin-right:auto;">
          <tr style="border-bottom: 10px solid transparent; ">
            <image-panel-td :droneIdx="0" :isHudShow="isHudShow" :imgHeight="smallImgHeight" style="border-right: 10px solid transparent;" />
            <image-panel-td :droneIdx="1" :isHudShow="isHudShow" :imgHeight="smallImgHeight" />
          </tr>
          <tr style="border-bottom: 10px solid transparent; ">
            <image-panel-td :droneIdx="2" :isHudShow="isHudShow" :imgHeight="smallImgHeight" style="border-right: 10px solid transparent;" />
            <image-panel-td :droneIdx="3" :isHudShow="isHudShow" :imgHeight="smallImgHeight" />
          </tr>
          <tr>
            <image-panel-td :isHudShow="isHudShow" :imgHeight="smallImgHeight" style="border-right: 10px solid transparent;" />
            <image-panel-td :isHudShow="isHudShow" :imgHeight="smallImgHeight" />
          </tr>
        </table>

        <table v-else-if="!modeMinimize" style="width: 100%; margin-left:auto; margin-right:auto;">
          <tr style="border-bottom: 10px solid transparent; ">
            <image-panel-td :droneIdx="0" :isHudShow="isHudShow" :imgHeight="exImgHeight" />
          </tr>
          <tr style="border-bottom: 10px solid transparent; ">
            <image-panel-td :droneIdx="1" :isHudShow="isHudShow" :imgHeight="exImgHeight" />
          </tr>
          <tr style="border-bottom: 10px solid transparent; ">
            <image-panel-td :droneIdx="2" :isHudShow="isHudShow" :imgHeight="exImgHeight" />
          </tr>
          <tr style="border-bottom: 10px solid transparent; ">
            <image-panel-td :droneIdx="3" :isHudShow="isHudShow" :imgHeight="exImgHeight" />
          </tr>
          <tr>
            <image-panel-td :isHudShow="isHudShow" :imgHeight="exImgHeight"/>
          </tr>
          <tr>
            <image-panel-td :isHudShow="isHudShow" :imgHeight="exImgHeight"/>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import ImagePanelTd from '@/components/views/main/image/indexTd.vue'

export default {
  name: 'image-panel',
  components: {
    ImagePanelTd
  },
  props: {
    isHudShow: {},
    modeMinimize: {},
    mainState: {}
  },
  setup() {
    const { getters } = useStore()
    const state = reactive({
      droneList: computed(() => getters['drone/getImageList']),
      bigImgHeight: 300,
      smallImgHeight: 150,
      exImgHeight: 210,
    })

    onMounted(() => {})

    return {
      ...toRefs(state)
    }
  }
}
</script>
