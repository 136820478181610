<template>
  <header-bar @setCameraPosition="setCameraPosition" @changeBaseLayer="changeBaseLayer"/>
  <main class="main" role="main" oncontextmenu="return false">
    <div class="main-inner" style="height: 0%!important">
      <div class="main-con">
        <div>
          <!-- <div id="videoArea"></div> -->
          <!--컴포넌트 시작-->
          <div class="hud-component small">
            <!--   드론 연결 박스 컴포넌트 -->
            <div style="height: calc(100vh - 75px); position: relative; overflow-y: auto;">
              <div class="component none" v-if="mobius_connected === 'disconnected'" style="margin-top: 0.5rem;">
                <p>미연결 상태입니다.<br Fhud-component/>설정에서 Mobius와 연결을 진행해 주십시오.</p>
              </div>
              <div class="component none" v-else-if="mobius_connected === 'connected' && !hudScroll" style="margin-top: 0.5rem;">
                <p>연결되었습니다.<br />설정에서 드론을 지정해 주십시오.</p>
              </div>
              <!-- 드론 목록 -->
              <div v-else v-for="drone in ctrlDroneList" :key="drone.id" style="margin-top: 0.5rem;">
                <main-connection-index
                  :mobius_connected="mobius_connected"
                  :modeTab="modeTab"
                  :ctrlDrone="drone"
                  :waypointList="waypointList"
                  :wayPointLayer="wayPointLayer"
                  :homePositionLayer="homePositionLayer"
                  :map="map"
                  :mainState="state"
                  @update="droneSelect"
                  @fnHudClick="fnHudClick"
                />
              </div>
            </div>
          </div>
        </div>
        <!--//컴포넌트 끝!-->
        <div id="realMap" class="realMap map bottom">
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { getCurrentInstance, reactive, computed, toRefs, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import HeaderBar from '@/components/common/layouts/TheHeader'
import MainConnectionIndex from '@/components/views/main/connection'
import ImagePanel from '@/components/views/main/image/index'
import ModeTab from '@/components/views/mode/tab'
import ModeIndex from '@/components/views/mode/index'
import { useModalState } from '@/common/state/ModalState'
import CREATE from '@/components/common/modal/created/index'

//* openlayers imports
import { Map } from 'ol'
import 'ol/ol.css'

//* external library
import $ from 'jquery'
import * as Cesium from 'cesium'
import axios from 'axios'

export default {
  name: 'main-index',
  components: { MainConnectionIndex, ImagePanel, ModeTab, ModeIndex, CREATE, HeaderBar },
  description: '메인 인덱스',
  setup() {
    const { proxy } = getCurrentInstance()
    const router = useRouter()
    const store = useStore()
    const { modalPopUp, fnModalAlert, fnBaseConfirm } = useModalState()
    const { dispatch, getters } = useStore()
    const fnView = () => {
      router.push({ path: '/main/view' })
    }
    const mobiusData = reactive({
      host: computed(() => getters['local/getHost']),
      ae: computed(() => getters['local/getAe'])
    })

    const state = reactive({
      tabIndex: 3,
      tabText: '3D지도',
      tabImage: 'i_map_3d_on.svg',

      // layout 설정 관련 변수
      modeTab: false,
      isHudShow: true,
      hudScroll: false,
      modeMinimize: true,
      rfData: computed(() => getters['user/getRfData']), // rf 관련 데이터
      isHidden: computed(() => getters['local/getIsHidden']),
      isRefresh: computed(() => getters['local/getWpRefresh']),
      getRouteLayout: computed(() => getters['layout/getRouteLayout']),
      mobius_connected: computed(() => getters['local/getMobiusConnected']),
      modalComponents: computed(() => getters['layout/getModalComponents']),
      modalAlertComponents: computed(() => getters['layout/getModalAlertComponents']),

      // drone 관련 변수
      isDistanceMonitor: false,
      hpList: [],
      droneArr: [],
      gpsLoading: [],
      registerROI: new Map(),
      waypointList: computed(() => getters['local/getWpList']),
      ctrlDroneList: computed(() => getters['drone/getCtrlDroneList']),
      missionDroneList: computed(() => getters['drone/getMissionDroneList']),

      // 지도 관련 변수
      map: null,
      mapItem: [],
      mapItemLoading: false,
      tileLayer: '',
      pointerCoor: {
        lng: null,
        lat: null
      },
      centerView: [],
      osmSource: null,
      selectedWps: [],
      editPattern: false,
      patternLength: null,
      videoBackground: '',
      clickedCoord: [127.06357, 37.50073],
      patternList: computed(() => getters['pattern/getPatternList']),

      // 3D map 관련
      rf: false, // rf test
      isRF: false,
      is3d: true,
      elevation: 0,
    })
    const map = reactive({
      viewer: null,
    })
    const layers = reactive({
      droneLayer: null,
      wpPathLayer: null,
      assistLayer: null,
      patternLayer: null,
      wayPointLayer: null,
      geofenceLayer: null,
      dronePathLayer: null,
      patternLineLayer: null,
      homePositionLayer: null,

      // 공역
      roiLayer: null,
      controlLayer: null,
      noFlyZoneLayer: null,
      dangerZoneLayer: null,
      borderZoneLayer: null,
      trainingZoneLayer: null,
      flightRestrictedLayer: null,
      militaryOperationLayer: null,
      lightVehiclePermissionedLayer: null
    })

    //* ctrlDroneList 유무 확인
    watch(
      () => state.ctrlDroneList,
      async ctrlDroneList => {
        if (ctrlDroneList == '') {
          state.hudScroll = false
          // layers.wayPointLayer.getSource().clear()
          // layers.assistLayer.getSource().clear()
          // layers.patternLayer.getSource().clear()
          // layers.patternLineLayer.getSource().clear()
          // layers.dronePathLayer.getSource().clear()
          // layers.wpPathLayer.getSource().clear()
          // layers.droneLayer.getSource().clear()
          // layers.homePositionLayer.getSource().clear()
          return
        } else {
          state.hudScroll = true
          // await loadGoto()
          // await getPatternList()
          ctrlDroneList.forEach(ctrlDrone => {
            state.registerROI.set(ctrlDrone.name, '')
          })
          for (let item of ctrlDroneList) {
            if (state.gpsLoading.filter(gps => gps.name == item.name).length == 0) {
              state.gpsLoading.push({ name: item.name, isLoading: false })
            }
          }
        }
      }
    )

    //* 모비우스 연결 확인
    watch(
      () => state.mobius_connected,
      connected => {
        if (connected) socketStart()
        else proxy.$SocketUtils.close()
      }
    )

    //* 미션 드론 리스트 유무 확인
    watch(
      () => state.missionDroneList.length,
      missionDroneCount => {
        if (missionDroneCount == 0) {
          state.modeTab = false
          state.droneArr = []
          // state.map.getInteractions().forEach(interaction => {
          //   if (interaction instanceof Select) {
          //     interaction.getFeatures().forEach(feature => interaction.getFeatures().remove(feature))
          //   }
          // })
        }
      }
    )

    //* waypoint refesh 확인
    watch(
      () => state.isRefresh,
      async val => {
        if (val == true) {
          await loadGoto()
        }
      }
    )

    onMounted(async () => {
      await initRfSettings()
      //* 지도 화면 설정
      if (localStorage.getItem('center')) {
        const center = localStorage.getItem('center').split(',')
        state.centerView = [parseFloat(center[0]), parseFloat(center[1])]
      } else {
        state.centerView = [128.472363, 37.1710663]
      }

      // if (window.innerWidth < 1081) {
      //   state.isRF = true
      //   $('.realMap')[0].style.height = $(window).height() / 2 + 150 + 'px'
      // } else {
        $('.realMap')[0].style.height = $(window).height() + 'px'
      // }
    
      mapInit3d()
      //dispatch('layout/updateMap', state.map)
      dispatch('layout/updateMainComponent', proxy)
      //await dispatch('drone/setMissionDrone', [])
      await fnConnectMobiusCheck()
    })

    const mapInit3d = async () => {
      await proxy.$CesiumUtils.init(map, state.centerView)

        // await get3dData()
        // await get3dPattern()
        // await proxy.$CesiumUtils.createWpline(map.viewer, state)
        // await proxy.$CesiumUtils.createPatternline(mao.viewer, state)

    }

    const fnConnectMobiusCheck = async () => {
      //* 모비우스와 연결되었는지 확인
      if (localStorage.getItem('mobius_connected') === 'connected') {
        await initDrone()
        get3dDroneModel()
        socketStart()
        //* 허드 전체 height 값 잡아주는 로직
        // $('.hud-component .component-box div .component-box').css('height', '498px')
      }
    }

    const initDrone = async () => {
      //* 시작시 droneList store에 등록
      const droneList = await dispatch('drone/getDroneList')
      await dispatch('drone/setDroneList', droneList)

      //* 시작시 ctrlDroneList store에 등록
      let ctrlDroneList = proxy.$Util.setCtrlDroneList(droneList)
      ctrlDroneList.sort((a, b) => a.sysId - b.sysId)
      await dispatch('drone/setCtrlDroneList', ctrlDroneList)
    }

    const fnCancel = async index => {
      const payload = {}
      if (index === 'nav-top') {
        if (state.getRouteLayout.rightNavTop === true) {
          payload.rightNavTop = false
        } else {
          payload.rightNavTop = true
        }
      } else if (index == 'nav-bot') {
        if (state.getRouteLayout.rightNavBot === true) {
          payload.rightNavBot = false
        } else {
          payload.rightNavBot = true
        }
      } else if (index == 'hud') {
        if (state.isHudShow === true) {
          state.isHudShow = false
          $('main > div > div.main-con > div.map.top.map-video > div.hud-component > div.component-box').css('display', 'none')
        } else {
          state.isHudShow = true
          $('main > div > div.main-con > div.map.top.map-video > div.hud-component > div.component-box').css('display', 'block')
        }
      }
      await dispatch('layout/updateRouteLayout', payload)
    }

    const fnMinimize = () => {
      state.modeMinimize = !state.modeMinimize
      if (state.modeMinimize) {
        //small
        $('.hud-component .component-box div .component-box').animate({ height: '498px' }, 300)
        $('.hud-component .component-box div .component').animate({ height: '482px' }, 300)
        $('.hud-component .component-box div .component .component-inner .component-inner-wrap .default_hud').animate({ height: '348px' }, 300)
        $('.hud-component .component-box div .component .component-inner .component-inner-wrap .default_hud > div').animate({ height: '348px' }, 300)
        $('.hud-component .component-box div .component .component-inner .component-inner-wrap .default_hud > div > div').animate(
          { height: '348px' },
          300
        )
        // $('.status-bar').css('height', '38rem')
        // $('.hud_background').css('top', '-65.5%')
        // $('.pitch_background').css('top', '21%')
        $('.pitch_background').css('left', '32.5%')
        $('.hudHeading').css('left', '-199px')
        $('.pitch_background').css('height', '215px')
        $('.valbox_head .hedcont').css('height', '30px')
        $('.valbox_head .hedcont').css('line-height', '28px')
        $('.armed_upsize').addClass('armed_default')
        $('.armed_default').removeClass('armed_upsize')
      } else {
        //big
        $('.hud-component .component-box div .component-box').animate({ height: '600px' }, 300)
        $('.hud-component .component-box div .component').animate({ height: '600px' }, 300)
        $('.hud-component .component-box div .component .component-inner .component-inner-wrap .default_hud').animate({ height: '468px' }, 300)
        $('.hud-component .component-box div .component .component-inner .component-inner-wrap .default_hud > div').animate({ height: '468px' }, 300)
        $('.hud-component .component-box div .component .component-inner .component-inner-wrap .default_hud > div > div').animate(
          { height: '468px' },
          300
        )

        // $('.status-bar').css('height', '50rem')
        // $('.hud_background').css('top', '-36%')
        // $('.pitch_background').css('top', '28%')
        $('.pitch_background').css('left', '37.5%')
        $('.hudHeading').css('left', '-279px')
        $('.pitch_background').css('height', '215px')
        $('.valbox_head .hedcont').css('height', '40px')
        $('.valbox_head .hedcont').css('line-height', '36px')
        $('.armed_default').addClass('armed_upsize')
        $('.armed_upsize').removeClass('armed_default')
      }
    }

    const fnShortCut = async () => {
      await modalPopUp(proxy.$modalAlertNames.SHORTCUT, () => {})
    }

    const droneSelect = async (val, drone) => {
      console.log(val, drone)
      if(val) {
        //비디오 켜짐
      } else {
        //비디오꺼짐
      }

      // const selectEvent = new SelectEvent()
      // const droneFeatures = layers.droneLayer.getSource().getFeatures()
      
      // //* 드론 컨트롤 모달 on/off
      // if (state.missionDroneList.length) state.modeTab = true
      // else state.modeTab = false


      // const selectFeature = droneFeatures.find(feature => feature.get('name') == drone.name)
      // if (!selectFeature) return // if drone feature is not present in map
      // const interactions = state.map.getInteractions()
      // const selectInteraction = interactions.getArray().find((interaction) => interaction instanceof Select)
      
      // const selectFeatures = selectInteraction.getFeatures();
      // const isSelected = selectFeatures.getArray().includes(selectFeature);

      // if (val && !isSelected) {
      //   selectFeatures.push(selectFeature);
      //   selectEvent.type = "select";
      //   selectEvent.target = state.droneArr;
      //   selectEvent.selected = [selectFeature];
      //   selectEvent.deselected = [];
      // } else if (!val && isSelected) {
      //   selectFeatures.remove(selectFeature);
      //   selectEvent.type = "select";
      //   selectEvent.selected = [];
      //   selectEvent.target = state.droneArr;
      //   selectEvent.deselected = [selectFeature];
      // }

      // selectEvent.mapBrowserEvent = new MapBrowserEvent();
      // selectInteraction.dispatchEvent(selectEvent);
    }

    const loadGoto = async () => {
      if (!state.ctrlDroneList) return

      // layers.wayPointLayer.getSource().clear()
      // layers.roiLayer.getSource().clear()

      const gotoArr = await proxy.$MavlinkUtils.loadGoto()
      let gotoList = gotoArr.filter(goto => {
        for (let drone of state.ctrlDroneList) {
          if (goto.drone == drone.name || goto.drone == '') return goto
        }
      })
      gotoList.sort((a, b) => (a.cuAt > b.cuAt ? 1 : -1))
      await store.dispatch('local/setWpList', gotoList)

      for (let i = 0; i < state.waypointList.length; i++) {
        let wp = state.waypointList[i]
        let wpFeature

        //* 드론이 지정되어 있는 waypoint 색상 등록
        if (wp.cuAt !== 0) {
          state.ctrlDroneList.forEach(drone => {
            if (wp.drone === drone.name) wp.color = drone.color
          })
        }

        //* Waypoint 타입이 관심 일 때
        if (wp.pointType == 'roi') {
          wpFeature = proxy.$OlUtils.createRoiFeature(wp)
          layers.roiLayer.getSource().addFeature(wpFeature)
          //* Waypoint 타입이 이동 일 때
        } else if (wp.pointType == 'move') {
          wpFeature = proxy.$OlUtils.createWayPointFeature(wp)
          await layers.wayPointLayer.getSource().addFeature(wpFeature)
          //* Waypoint 타입이 선회 일 때
        } else if (wp.pointType == 'loiter') {
          wpFeature = proxy.$OlUtils.createWayPointFeature(wp)
          await layers.wayPointLayer.getSource().addFeature(wpFeature)
        }
      }
      // await proxy.$OlUtils.waypointPath(state, layers)
      await dispatch('local/setWpRefresh', false)
    }

    const get3dData = async () => {
        for (var i = 0; i < state.waypointList.length; i++) {
          var modelInfo = {
            wpInfo: state.waypointList[i]
          }
          await proxy.$CesiumUtils.createWpModel(map.viewer, modelInfo)
          await proxy.$CesiumUtils.createMissionPole(map.viewer, modelInfo, 'waypoint')
        }
    }

    const get3dPattern = async () => {
        for (var i = 0; i < state.patternList.length; i++) {
          await proxy.$CesiumUtils.createPatternModel(map.viewer, state.patternList[i])
        }  
    }

    //* [ SOCKET ] when drones are connected
    const handleConnectedDrone = async (droneData) => {
      const inactive = $(`#${droneData.id}`)
      const inactive_vicon = $(`#${droneData.id}_vicon`)
      const inactive_tab = $(`#${droneData.id}_tab`)
      if (inactive.hasClass('inactive_hud')) {
        inactive.removeClass('inactive_hud')
        inactive_vicon.removeClass('inactive_hud')
        inactive_tab.removeClass('inactive_hud')
      }
      const {position} = droneData
      const elevation = await proxy.$ElevationUtils.getElevation(position)
      proxy.$CesiumUtils.liveDrone3d(map.viewer, droneData, elevation)
      setDroneInfo(droneData)
    }

    const setDroneInfo = (droneData) => {
      let homeDistance
      const { longitude, latitude } = droneData.position
      const ctrlDrone = state.ctrlDroneList.find(drone => drone.id === droneData.id)

      if (!ctrlDrone) return
      if (ctrlDrone.flightPath.length > 0) {
        if (
          ctrlDrone.flightPath[ctrlDrone.flightPath.length - 1][0] !== longitude ||
          ctrlDrone.flightPath[ctrlDrone.flightPath.length - 1][1] !== latitude
        ) {
          ctrlDrone.flightPath.push([longitude, latitude])
        }
      } else {
        ctrlDrone.flightPath.push([longitude, latitude])
      }

      if (ctrlDrone.flightPath.length > 1000) ctrlDrone.flightPath.shift()

      //* 드론 데이터 저장
      if (ctrlDrone.id == droneData.id) {
        let droneInfo = {
          ekfStatus: {
            airspeed_variance: droneData.airSpeed.airSpeed,
            velocity_variance: droneData.airSpeed.groundSpeed
          },
          airSpeed: droneData.airSpeed,
          homeDistance: homeDistance,
          position: droneData.position, //* 드론 위치 정보
          attitude: droneData.attitude, //* 드론 높이 정보
          groundSpeed: droneData.groundSpeed, //? 드론 속도 정보
          battStatus: droneData.battStatus, //* 드론 배터리 정보
          gpsStatus: droneData.gpsStatus, //? 드론 RF 통신 정보
          status: droneData.status,
          lteData: droneData.lteData,
          heading: droneData.heading,
          armStatus: droneData.armStatus,
          autopilot: droneData.autopilot,
          flightMode: droneData.flightMode,
          relative_alt: droneData.relative_alt,
          // relative_alt: parseInt(droneData.position.z),
          rcChannelRssi: droneData.rcChannelRssi
        }
        ctrlDrone.data = droneInfo
        ctrlDrone.takeoffDelay = droneData.takeoffDelay
        ctrlDrone.id = droneData.id

        ctrlDrone.targetBearing = droneData.targetBearing
        ctrlDrone.wpDist = droneData.wpDist
        ctrlDrone.currentWpSeq = droneData.currentWpSeq
        ctrlDrone.connected = true

        if (droneData.takeoffTime) ctrlDrone.flightingTime = msToTime(new Date() - new Date(droneData.takeoffTime))
        else ctrlDrone.flightingTime = '00:00'
      }
      $('#' + droneData.id + ' .heading_gauge').css('left', -770.329 - droneData.heading * 3.413888888888889 + 'px')
        $('#' + droneData.id + ' .alt_gauge').css('top', -7658.5 + (droneData.relative_alt / 1000) * 7.7405 + 'px')
        $('#' + droneData.id + ' .speed_gauge').css(
          'top',
          -402 +
            Math.sqrt(
              droneData.groundSpeed.x * droneData.groundSpeed.x +
                droneData.groundSpeed.y * droneData.groundSpeed.y +
                droneData.groundSpeed.z * droneData.groundSpeed.z
            ) *
              8.016666666666667 +
            'px'
        )
        $('#' + droneData.id + ' .pitch_gauge').css('top', -224 + droneData.attitude.pitch * 3.577777777777778 + 'px')
        $('#' + droneData.id + ' .hud_background').css('top', -228 + droneData.attitude.pitch * 3.577777777777778 + 'px')
        $('#' + droneData.id + ' .pitch_gauge').css('transform', 'rotate(' + -droneData.attitude.roll + 'deg)')
        $('#' + droneData.id + ' .hud_background').css('transform', 'rotate(' + -droneData.attitude.roll + 'deg)')
        $('#' + droneData.id + ' .roll_gauge').css('transform', 'rotate(' + -droneData.attitude.roll + 'deg)')
        $('#' + droneData.id + ' .pitch_background').css('transform', 'rotate(' + -droneData.attitude.roll + 'deg)')
    }

    //* [ SOCKET ] when drones are not connected
    const handleDisconnectedDrone = (droneData) => {
      const inactive = $(`#${droneData.id}`)
      const inactive_vicon = $(`#${droneData.id}_vicon`)
      const inactive_tab = $(`#${droneData.id}_tab`)
      if (!inactive.hasClass('inactive_hud')) {
        inactive.addClass('inactive_hud')
        inactive_vicon.addClass('inactive_hud')
        inactive_tab.addClass('inactive_hud')
      }
      proxy.$CesiumUtils.deleteDroneModel(map.viewer, droneData.id)

      // Reset state for all control drones
      // layers.dronePathLayer.getSource().clear()
      for (let ctrlDrone of state.ctrlDroneList) {
        ctrlDrone.data.armStatus = 0
        ctrlDrone.data.heading = 0
        ctrlDrone.flightingTime = '00:00'
        ctrlDrone.connected = false
        ctrlDrone.flightPath = []
      }
    }

    const socketStart = () => {
      proxy.$SocketUtils.pilotConnect()
      proxy.$SocketUtils.addListener('drone.data', param => {
        const droneData = JSON.parse(param)
        console.log(droneData)
        if (droneData.status === -1) {
          handleDisconnectedDrone(droneData)
        } else {
          handleConnectedDrone(droneData)
        }
      })

      //* 드론 파라미터 관련 설정
      proxy.$SocketUtils.addListener('drone.params', parameter => {
        const droneName = parameter.droneName
        const paramDroneList = getters['drone/getParams']
        const isIncluded = paramDroneList.some(item => item.droneName === droneName)
        if (!isIncluded) {
          const droneParamInfo = { droneName, parameters: parameter.params }
          dispatch('drone/setDroneParams', droneParamInfo)
        }
      })

      //! 파라미터 update 관련 변경 필요
      proxy.$SocketUtils.addListener('drone.params.update', param => {
        const droneName = param.droneName
        const params = param.params
        const result = param.result
        const paramDroneList = getters['drone/getParams']

        if (result) {
          const droneParameters = paramDroneList.find(drone => drone.droneName == droneName)
          if (droneParameters) {
            const idx = droneParameters.parameters.findIndex(param => {
              return param.param_id == params.param_id
            })
            if (idx > -1) {
              droneParameters.parameters[idx].param_value = params.param_value
            } else {
              return $.toast({
                heading: 'Information-' + droneName,
                text: `파라미터 ${params.param_id} 변경에 실패했습니다.`,
                showHideTransition: 'fade',
                icon: 'warning',
                position: 'bottom-right'
              })
            }
            const droneParamInfo = { droneName: droneName, parameters: droneParameters.parameters }
            dispatch('drone/setDroneParams', droneParamInfo)
          }
        }
        proxy.$MavlinkUtils.nextQueueExecution(droneName)
      })

      //* 드론정보 화면에 표시
      proxy.$SocketUtils.addListener('drone.status.error', ({ droneName, error }) => {
        const message = error.split(' ')

        state.ctrlDroneList.forEach(drone => {
          if (message[0] === 'Mission') {
            drone.currentPatternNo = parseInt(message[1])
          }
        })

        const loadingInfo = state.gpsLoading.find(info => info.name === droneName)
        if (loadingInfo) {
          loadingInfo.isLoading = message[2] === 'calibration'
        }

        if (error === 'finishPattern') {
          proxy.$LxUtils.stop(droneName)
        }

        // $.toast({
        //   heading: 'Information-' + droneName,
        //   text: error,
        //   showHideTransition: 'fade',
        //   icon: 'info',
        //   position: 'bottom-right'
        // })
      })
    }

    const get3dDroneModel = async () => {
      for (let item of state.ctrlDroneList) {
        if (!(item.control == -1)) {
          let elevation = await proxy.$ElevationUtils.getElevation(item.data.position)
          proxy.$CesiumUtils.createDroneModel(map.viewer, item.id, item.data, item.color, elevation)
        }
      }
    }

    const getPatternList = async () => {
      try {
        if (!state.ctrlDroneList) return
        const patternData = await proxy.$PatternUtils.getPattern()
        const patternList = []
        layers.patternLayer.getSource().clear()
        layers.patternLineLayer.getSource().clear()

        for (let pattern of patternData.data) {
          if (mobiusData.host == pattern.host && mobiusData.ae == pattern.aeName) {
            const shootPeriod = (pattern.Hsensor * pattern.alt * ((100 - pattern.multiplicity) / 100)) / (pattern.focalLength * pattern.speed)
            pattern.shootPeriod = parseInt(shootPeriod)
            await proxy.$OlUtils.makeDefaultPattern(state, layers, pattern, true)
            patternList.push(pattern)
          }
        }

        dispatch('pattern/setPatternList', patternList)
      } catch (error) {
        throw new Error(error)
      }
    }

    const initRfSettings = async () => {
      try {
        const result = await proxy.$AuthUtils.getUserRFData()
        if (result.status == 200) {
          dispatch('user/initRfData', result.data)
        }
      } catch (error) {
        throw new Error(error)
      }
    }

    const fnHudClick = (v) => {
      console.log(v)
      //지도카메라 이동
      //드론 네임 넘겨서 해당 엔티티의 위치값으로 지도 이동
      proxy.$CesiumUtils.setCameraEntity(map.viewer, v)
    }

    const setCameraPosition = (position) => {
      console.log(position)
      proxy.$CesiumUtils.setCameraPosition(map.viewer, position)
    }
    
    const changeBaseLayer = (isAerialMap) => {
      console.log(isAerialMap)
      proxy.$CesiumUtils.changeBaseLayer(map.viewer, isAerialMap)
    }

    return {
      ...toRefs(state),
      state,
      ...toRefs(layers),
      layers,
      ...toRefs(mobiusData),
      ...toRefs(map),
      fnView,
      fnConnectMobiusCheck,
      fnCancel,
      fnMinimize,
      fnShortCut,
      droneSelect,
      socketStart,
      loadGoto,
      initDrone,
      mapInit3d,
      get3dDroneModel,
      get3dData,
      get3dPattern,
      getPatternList,
      fnHudClick,
      setCameraPosition,
      changeBaseLayer
    }
  }
}
</script>

<style scoped>
@media (min-width: 1081px) {
  .map.top.map-video {
    height: 0px;
  }
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  border: 5px solid rgba(180, 180, 180, 0.6);
  border-top-color: rgba(0, 0, 0, 0.6);
  animation: spinner 0.6s linear infinite;
}

.jq-toast-wrap.bottom-right {
  bottom: 45px !important;
  right: 50px !important;
}

.jq-toast-single {
  min-width: 320px !important;
  font-size: 15px !important;
}

.jq-toast-single h2 {
  margin-bottom: 25px !important;
}
</style>
