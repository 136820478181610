const text = {
  loadFence: '선택한 비행체에 지정할 지오펜스 영역 전달 명령',
  CONTROL: '선택한 비행체의 임무 장비 제어 명령',
  alt: '선택한 비행체에 설정한 고도로 이륙 명령',
  INTEREST: '선택한 비행체에 ROI(Region Of Interest) 설정 명령',
  return: '선택한 비행체에 귀환 명령',
  mode: '선택한 비행체의 모드 제어',
  loiter: '선택한 비행체에 설정한 반경과 속도로 선회 비행 명령',
  speed: '선택한 비행체에 설정한 속도로 속도 변경 명령',
  arm: '선택한 비행체에 시동 명령',
  takeoff: '선택한 비행체에 설정한 고도로 이륙 명령',
  MISSION2: '선택한 비행체의 임무 장비 제어',
  MISSION: '선택한 비행체의 임무 장비 제어',
  autoMission: '선택한 비행체에 설정한 비행좌표 리스트를 다운로드하고 자동 비행 명령',
  stop: '선택한 비행체에 제자리 비행 명령',
  disarm: '선택한 비행체에 시동 끄기 명령',
  move: '선택한 비행체를 선택한 지점으로 이동 명령',
  land: '선택한 비행체에 착륙 명령',
  DROP: '선택한 비행체에 시동 명령',
  SETTING: '선택한 비행체의 파라미터 설정',
  PATTERN: '선택한 비행체에 설정한 패턴과 속도, 고도로 비행 명령',
  capture: '선택한 비행체에 촬영 명령 전송'
}

export default text
