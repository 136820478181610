<template>
  <div class="basebox">
    <!--pc-->
    <div class="assignment-tab pc" v-if="mobius_connected !== 'false'">
      <div class="assignment-box">
        <ul class="assignment-inner">
          <li class="assignment-li" :class="{ on: index === 'mode' }" @click="fnModalPopUp('mode')">모드</li>
        </ul>
        <ul class="assignment-inner">
          <li class="assignment-li" :class="{ on: index === 'arm' }" @click="fnModalPopUp('arm')">시동</li>
          <li class="assignment-li" :class="{ on: index === 'takeoff' }" @click="fnModalPopUp('takeoff')">이륙</li>
          <li class="assignment-li" :class="{ on: index === 'land' }" @click="fnModalPopUp('land')">착륙</li>
          <li class="assignment-li" :class="{ on: index === 'disarm' }" @click="fnModalPopUp('disarm')">종료</li>
          <li class="assignment-li" :class="{ on: index === 'stop' }" @click="fnModalPopUp('stop')">정지</li>
          <li class="assignment-li" :class="{ on: index === 'return' }" @click="fnModalPopUp('return')">귀환</li>
          <li class="assignment-li" :class="{ on: index === 'loadFence' }" @click="fnModalPopUp('loadFence')">지오펜스</li>
        </ul>

        <ul class="assignment-inner">
          <li class="assignment-li" :class="{ on: index === 'move' }" @click="fnModalPopUp('move')">이동</li>
          <li class="assignment-li" :class="{ on: index === 'loiter' }" @click="fnModalPopUp('loiter')">선회</li>
          <li class="assignment-li" :class="{ on: index === 'autoMission' }" @click="fnModalPopUp('autoMission')">자동</li>
          <li class="assignment-li" :class="{ on: index === 'PATTERN' }" @click="fnModalPopUp('PATTERN')">패턴</li>
          <li class="assignment-li" :class="{ on: index === 'INTEREST' }" @click="fnModalPopUp('INTEREST')">관심</li>
          <li class="assignment-li" :class="{ on: index === 'speed' }" @click="fnModalPopUp('speed')">속도</li>
          <li class="assignment-li" :class="{ on: index === 'alt' }" @click="fnModalPopUp('alt')">고도</li>
          <li class="assignment-li" :class="{ on: index === 'alt' }" @click="fnModalPopUp('capture')">촬영</li>
        </ul>
        <!-- <ul class="assignment-inner">
          <li class="assignment-li" :class="{ on: index === 'MISSION' }" @click="fnModalPopUp('MISSION')">임무</li>
          <li class="assignment-li" :class="{ on: index === 'CONTROL' }" @click="fnModalPopUp('CONTROL')">제어</li>
        <li class="assignment-li" :class="{ on: index === 'SETTING' }" @click="fnModalPopUp('SETTING')">설정</li>
        <li class="assignment-li" :class="{ on: index === 'DROP' }" @click="fnModalPopUp('DROP')">투하</li>
        </ul> -->
      </div>
      <div class="channel-area">
        <p>{{ text[index] }}</p>
      </div>
    </div>

    <!-- 노트북 -->
    <div class="assignment-tab lab" v-if="mobius_connected !== 'false'">
      <div class="assignment-box">
        <div class="assignment-inner">
          <div class="assignment-li resp" :class="{ on: index === 'mode' }" @click="fnModalPopUp('mode')">모드</div>
        </div>
        <div class="assignment-inner">
          <div class="assignment-li resp" :class="{ on: index === 'arm' }" @click="fnModalPopUp('arm')">시동</div>
          <div class="assignment-li resp" :class="{ on: index === 'takeoff' }" @click="fnModalPopUp('takeoff')">이륙</div>
          <div class="assignment-li resp" :class="{ on: index === 'land' }" @click="fnModalPopUp('land')">착륙</div>
          <div class="assignment-li resp" :class="{ on: index === 'disarm' }" @click="fnModalPopUp('disarm')">종료</div>
        </div>
        <div class="assignment-inner">
          <div class="assignment-li resp" :class="{ on: index === 'stop' }" @click="fnModalPopUp('stop')">정지</div>
          <div class="assignment-li resp" :class="{ on: index === 'return' }" @click="fnModalPopUp('return')">귀환</div>
          <div class="assignment-li resp" :class="{ on: index === 'INTEREST' }" @click="fnModalPopUp('INTEREST')">관심</div>
          <div class="assignment-li resp" :class="{ on: index === 'speed' }" @click="fnModalPopUp('speed')">속도</div>
          <div class="assignment-li resp" :class="{ on: index === 'alt' }" @click="fnModalPopUp('alt')">고도</div>
        </div>
        <div class="assignment-inner">
          <div class="assignment-li resp" :class="{ on: index === 'move' }" @click="fnModalPopUp('move')">이동</div>
          <div class="assignment-li resp" :class="{ on: index === 'loiter' }" @click="fnModalPopUp('loiter')">선회</div>
          <div class="assignment-li resp" :class="{ on: index === 'autoMission' }" @click="fnModalPopUp('autoMission')">자동</div>
          <div class="assignment-li resp" :class="{ on: index === 'PATTERN' }" @click="fnModalPopUp('PATTERN')">패턴</div>
          <div class="assignment-li resp" :class="{ on: index === 'loadFence' }" @click="fnModalPopUp('loadFence')">지오펜스</div>
        </div>
        <!-- <div class="assignment-inner">
          <div class="assignment-li" :class="{ on: index === 'MISSION' }" @click="fnModalPopUp('MISSION')">임무</div>
          <div class="assignment-li" :class="{ on: index === 'CONTROL' }" @click="fnModalPopUp('CONTROL')">제어</div>
          <div class="assignment-li" :class="{ on: index === 'SETTING' }" @click="fnModalPopUp('SETTING')">설정</div>
          <div class="assignment-li" :class="{ on: index === 'DROP' }" @click="fnModalPopUp('DROP')">투하</div>
        </div> -->
      </div>
      <div class="channel-area">
        <p>{{ text[index] }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, reactive, toRefs, watch } from 'vue'
import text from './text'
import { useStore } from 'vuex'

export default {
  name: 'mode-tab',
  props: {
    mainState: {}
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { getters, dispatch } = useStore()

    watch(
      () => props.mainState.tabMenu,
      tabMenu => {
        state.index = tabMenu
      }
    )

    const state = reactive({
      text: text,
      index: props.mainState.tabMenu,
      mobius_connected: computed(() => getters['local/getMobiusConnected'])
    })
    const fnMenuOn = val => {
      state.index = val
    }
    const fnModalPopUp = async index => {
      state.index = index
      dispatch('layout/updateModeTab', index)
      if (index !== 'MISSION') {
        proxy.$emit('update', index)
      } else {
        proxy.$emit('update', index)
      }
    }

    return {
      fnModalPopUp,
      fnMenuOn,
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
.resp {
  width: 12.7rem !important;
}
</style>
