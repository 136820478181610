<template>
  <!-- [A01] GNB -->
  <div class="gnb">
    <ul>
      <li><a href="#" class="logo_knps"><!-- KNPS 국립공원 로고 --></a></li>
      <li @click="setCameraPosition()" class="gnb01">현재위치</li> <!-- 선택시 class="gnb01 on" -->
      <li @click="fnDroneList()" class="gnb02">드론설정</li>
      <li @click="fnLogOut()" class="gnb03">로그아웃</li>
      <li>
        <a v-if="isAerialMap" @click="changeBaseLayer()" class="gnb04">일반지도</a>
        <a v-else @click="changeBaseLayer()" class="gnb04">위성지도</a>
      </li>
    </ul>
    <div></div>	
  </div>

  <!-- <div style="position: absolute; z-index: 999; top: 80px; left: 500px; background: #dff0db; border-radius: 4px; padding: 5px;" >

        <a @click="fnUser"><div class="ico ico-edit"></div>
          <span>회원정보 수정</span>
        </a>

        <a @click="fnLogOut"><div class="ico ico-logout"></div>
          <span>로그아웃</span>
        </a>

  </div>	 -->


</template>

<script>
import { computed, getCurrentInstance, reactive, toRefs, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useModalState } from '@/common/state/ModalState'
import { modalAlertNames } from '@/components/common/globalComponent'
import Geolocation from 'ol/Geolocation'

const layoutState = () => {
  const { getters } = useStore()
  const routeLayout = computed(() => {
    return getters['layout/getRouteLayout']
  })
  return {
    routeLayout
  }
}
export default {
  name: 'the-header',
  setup() {
    const { dispatch, getters } = useStore()
    const { fnModalConfirmCustom, modalPopUp } = useModalState()
    const { proxy } = getCurrentInstance()

    const fnBack = () => {
      proxy.$router.go(-1)
    }
    const state = reactive({
      mobius_connected: computed(() => {
        return getters['local/getMobiusConnected']
      }),
      rfData: computed(() => getters['user/getRfData']),
      show: false,
      isClicked: false,
      isDistanceMonitor: false,
      map: computed(() => getters['layout/getMap']),
      geolocation: '',
      isAerialMap: true,
    })

    onMounted(() => {
      // const geolocation = new Geolocation({
      //   trackingOptions: {
      //     enableHighAccuracy: true
      //   },
      //   projection: 'EPSG:4326',
      //   tracking: false
      // })
      navigator.geolocation.getCurrentPosition(function(pos){
        state.geolocation = [pos.coords.longitude, pos.coords.latitude]
      })
      // state.geolocation.on('change', function() {
      //   const currentPosition = state.geolocation.getPosition()
      //   state.map.getView().animate({ center: currentPosition, duration: 1000, zoom: 17 })
      //   state.geolocation.setTracking(false)
      // })
    })

    // me
    const fnDroneList = async callBack => {
      state.isClicked = true
      dispatch('drone/setMissionDrone', [])
      const payload = {}
      payload.component = proxy.$modalAlertNames.DRONELIST
      payload.callBack = callBack
      await dispatch('layout/pushModalAlertComponent', payload)
      setTimeout(() => {
        state.isClicked = false
      }, 500)
    }
    const fnTab = () => {
      state.isTabShow = !state.isTabShow
    }
    const fnUser = async () => {
      await dispatch('drone/setMissionDrone', [])
      // 회원 정보 노출
      state.show = false
      const callBack = async callback => {
        await modalPopUp(modalAlertNames.USERSTEP2, () => {}, callback)
        // proxy.$emit('close')
      }
      await modalPopUp(modalAlertNames.USERSTEP1, callback => callBack(callback.form))
    }

    const fnModalPopUp = async index => {
      // modalAlertNames.`${index}``
      if (index !== 'MISSION') {
        await modalPopUp(modalAlertNames.MODE, () => {}, index)
      } else {
        await modalPopUp(modalAlertNames.MISSION, () => {}, index)
      }
    }

    const fnLogOut = () => {
      proxy.$store.dispatch('user/signOut')
    }

    const setIsDistanceMonitor = async () => {
      state.isDistanceMonitor = !state.isDistanceMonitor
      await dispatch('drone/setDistanceMonitor', !state.isDistanceMonitor)
    }

    const setCameraPosition = () => {
      if(!state.geolocation) {
        alert("이 사이트의 위치 액세스를 허용해주세요") 
        return
      }
      // const currentPosition = state.geolocation.getPosition()
      // state.geolocation.setTracking(false)
      // const currentPosition = [127.054143, 37.272968]
      proxy.$emit('setCameraPosition', state.geolocation)
    }

    const changeBaseLayer = () => {
      state.isAerialMap = !state.isAerialMap
      proxy.$emit('changeBaseLayer', state.isAerialMap)
    }

    return {
      ...layoutState(),
      ...toRefs(state),
      fnBack,
      fnDroneList,
      fnTab,
      fnUser,
      fnLogOut,
      fnModalPopUp,
      setIsDistanceMonitor,
      setCameraPosition,
      changeBaseLayer
    }
  }
}
</script>

<style scoped>

</style>