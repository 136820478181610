<template>
  <div
    class="component-box"
    style="height: 100%; margin-top: 0rem; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none;"
  >
    <div class="component" :class="{ none: mobius_connected === 'false' }">
      <p v-show="mobius_connected === 'false'">Connection Failed</p>
      <div class="component-name" v-show="mobius_connected !== 'false'">
        <div class="left">
          <div class="color" :style="{ background: ctrlDrone.color }"></div>
          <div class="drone-name-box">
            <div class="drone-name">
              <p>{{ ctrlDrone.name }}</p>
            </div>
          </div>
        </div>
        <div class="right">
          <div :id="`${ctrlDrone.id}_vicon`" class="inactive_hud">
            <button @click="onClickVideo()">
              <img v-if="!isVideo" src="@/assets/static/images/i_video.svg"/>
              <img v-else src="@/assets/static/images/i_video-off.svg"/>
            </button>
          </div>
        </div>
      </div>
      <div class="component-inner" v-show="mobius_connected !== 'false'">
        <div :id="`${ctrlDrone.id}_tab`" class="component-inner-wrap inactive_hud">
          <div class="data-tab">
            <div class="data">
              <i class="data-tab-ico airplane"></i>
              <p>{{ ctrlDrone.data.gpsStatus.count }}</p>
            </div>
            <div class="data">
              <!-- y- yellow / w- white / r - red / g - green -->
              <i class="data-tab-ico wifi w" v-if="rsrpStrength === 1"></i>
              <i class="data-tab-ico wifi r" v-else-if="rsrpStrength === 2"></i>
              <i class="data-tab-ico wifi y" v-else-if="rsrpStrength === 3"></i>
              <i class="data-tab-ico wifi g" v-else-if="rsrpStrength === 4"></i>
              <p>{{ rsrp }}</p>
            </div>
            <div class="data">
              <!-- y- yellow / w- white / r - red / g - green / no- no signal -->
              <i class="data-tab-ico signal g"></i>
              <p>0</p>
            </div>
            <div class="data">
              <!-- alert / enough / full / low -->
              <i class="ico-battery low" v-if="batStatus == 1"></i>
              <i class="ico-battery alert" v-if="batStatus == 2"></i>
              <i class="ico-battery enough" v-if="batStatus == 3"></i>
              <i class="ico-battery full" v-if="batStatus == 4"></i>
              <p>{{ (ctrlDrone.data.battStatus.voltage / 1000).toFixed(1) }}V</p>
            </div>
            <div class="data">
              <i class="data-tab-ico time"></i>
              <p v-if="ctrlDrone.flightingTime">{{ ctrlDrone.flightingTime }}</p>
              <p v-else>00:00</p>
            </div>
          </div>
          <div style="display: flex;">
            <div
              :id="ctrlDrone.id"
              style="overflow: hidden; position: relative; width: 100%;"
              class="inactive_hud default_hud"
              v-if="isVideo"
            >
              <video-index :droneName="ctrlDrone.name"/>
            </div>
            <div
              :id="ctrlDrone.id"
              style="overflow: hidden; position: relative; width: 100%;"
              class="inactive_hud default_hud"
              @click="fnHudClick(ctrlDrone)"
              v-else
            >
              <div v-if="showSpinner" style="overflow: hidden; height: 100%; width: 100%">
                <div class="loader">Loading...</div>
              </div>
              <div v-else>
                <hud-panel :drone="ctrlDrone" :isVideo="isVideo"/>
              </div>
            </div>
            <div :id="ctrlDrone.id + 'area'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, getCurrentInstance, reactive, toRefs, watch, onMounted } from 'vue'
import { modalAlertNames } from '@/components/common/globalComponent'
import { useStore } from 'vuex'
import { useModalState } from '@/common/state/ModalState'
import HudPanel from '@/components/views/main/hud/index.vue'
import VideoIndex from '@/components/views/main/connection/video-index.vue'

// import kurentoUtils from 'kurento-utils'
// import io from 'socket.io-client'
import $ from 'jquery'
import axios from 'axios'

export default {
  name: 'main-connection-index',
  components: { HudPanel, VideoIndex },
  props: {
    mobius_connected: {
      type: [Boolean],
      default: false
    },
    modeTab: {
      type: Boolean,
      default: false
    },
    modeMinimize: {},
    ctrlDrone: {
      type: Object
    },
    waypointList: {
      type: Array
    },
    mainState: {},
    homePositionLayer: {},
    wayPointLayer: {},
    map: {}
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { dispatch, getters } = useStore()
    const { fnModalAlert, modalPopUp } = useModalState()
    const state = reactive({
      isMission: computed(() => {
        const droneList = getters['drone/getMissionDroneList']
        if(droneList.find(drone => drone.id == props.ctrlDrone.id)) {
          return true
        } else {
          return false
        }
      }),
      imageDroneList: computed(() => getters['drone/getImageList']),
      sseAccounts: computed(() => getters['sse/getSseAccounts']),
      optionTab: false,
      optionImage: [],
      batStatus: 1,
      rsrpStrength: 1,
      rsrp: '',
      selectedVersion: 'v1',
      showMode: {},
      isImage: false,
      isVideo: false,
      videoTag: false,
      alertBattery: props.ctrlDrone.cell * 3.59, // 21.54
      lowBattery: props.ctrlDrone.cell * 3.75, // 22.5
      midBattery: props.ctrlDrone.cell * 3.95, // 23.7
      highBattery: props.ctrlDrone.cell * 4.2, // 25.2

      showSpinner: false,
      sse: null,
      rfData: computed(() => getters['user/getRfData'])
    })
    /** watch **/
    watch(
      () => state.isMission,
      isMission => {
        if(isMission) state.optionTab = true
        else if(!isMission) state.optionTab = false
      }
    )
    watch(
      () => state.imageDroneList,
      async val => {
        state.optionImage = val
      },
      { deep: true }
    )
    watch(
      () => state.optionImage,
      async val => {
        if (val.length > 4) state.optionImage.pop()
      },
      { deep: true }
    )

    watch(
      () => props.ctrlDrone.data.battStatus,
      async batStatus => {
        const battery = parseFloat(batStatus.voltage) / 1000 // 12.587
        if (state.highBattery <= battery) {
          state.batStatus = 4
        } else if (state.midBattery <= battery && battery <= state.highBattery) {
          state.batStatus = 3
        } else {
          state.batStatus = 2
        }
      },
      { deep: true }
    )
    watch(
      () => props.ctrlDrone.data.lteData,
      async lteData => {
        if (lteData !== null) {
          state.rsrp = lteData.rsrp
          if (-70 <= lteData.rsrp) {
            state.rsrpStrength = 4
          } else if (-85 <= lteData.rsrp && lteData.rsrp < -70) {
            state.rsrpStrength = 3
          } else if (-100 <= lteData.rsrp && lteData.rsrp < -85) {
            state.rsrpStrength = 2
          } else if (lteData.rsrp < -100) {
            state.rsrpStrength = 1
          }
        } else {
          state.rsrpStrength = 1
        }
      },
      { deep: true }
    )
    watch(
      () => props.mainState.gpsLoading,
      val => {
        val.forEach(item => {
          if (item.name == props.ctrlDrone.name) {
            state.showSpinner = item.isLoading
          }
        })
      },
      { deep: true }
    )
    onMounted(() => {
      //* battery init
      const battery = parseFloat(props.ctrlDrone.data.battStatus.voltage) / 1000
      if (state.highBattery <= battery) {
        state.batStatus = 4
      } else if (state.midBattery <= battery && battery <= state.highBattery) {
        state.batStatus = 3
      } else if (state.alertBattery <= battery && battery <= state.midBattery) {
        state.batStatus = 2
      } else {
        state.batStatus = 1
      }

      //* rsrp init
      const lteData = props.ctrlDrone.data.lteData
      if (lteData !== null) {
        state.rsrp = lteData.rsrp
        if (-70 <= lteData.rsrp) {
          state.rsrpStrength = 4
        } else if (-85 <= lteData.rsrp && lteData.rsrp < -70) {
          state.rsrpStrength = 3
        } else if (-100 <= lteData.rsrp && lteData.rsrp < -85) {
          state.rsrpStrength = 2
        } else if (lteData.rsrp < -100) {
          state.rsrpStrength = 1
        }
      } else {
        state.rsrpStrength = 1
      }
    })

    const updateMavVersion = async (e, ctrlDrone) => {
      const data = {
        action: 'update',
        info: {
          ae: ctrlDrone.ae,
          name: ctrlDrone.name,
          id: ctrlDrone.id
        },
        value: JSON.parse(e.target.value),
        type: 'mavVersion'
      }
      await proxy.$DroneUtils.updateDroneByType(data)
    }

    const generateRandomString = () => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      let result = ''
      const charactersLength = characters.length
      for (let i = 0; i < 6; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }

      return result
    }

    const zoomToWp = wp => {
      const wpList = props.wayPointLayer.getSource().getFeatures()
      const gotoWp = wpList.find(feature => {
        let result
        const wpInfo = feature.get('prop')
        if (wpInfo.drone == wp.drone && wpInfo.cuAt == wp.cuAt) {
          result = feature
        }
        return result
      })
      const coord = gotoWp.getGeometry()
      props.map.getView().fit(coord, { duration: 1000, maxZoom: 15 })
    }

    const zoomToHomePosition = async droneName => {
      const hpList = props.homePositionLayer.getSource().getFeatures()
      if (hpList.length > 0) {
        const gotoHp = hpList.find(feature => {
          let result
          const hpInfo = feature.get('prop')
          if (hpInfo.droneName == droneName) {
            result = feature
          }
          return result
        })
        const hpFeature = gotoHp.getGeometry()
        props.map.getView().fit(hpFeature, { duration: 1000, maxZoom: 15 })
      } else {
        await fnModalAlert('알람', '드론을 먼저 연결해 주세요.', '')
      }
    }

    const fnLogs = async droneName => {
      let exportString = 'QGC WPL 110\n0 1 0 16 0 0 0 0 0 0 0 1\n'
      for (let i = 0; i < props.waypointList.length; i++) {
        let wayPointString
        if (props.waypointList[i].drone == droneName) {
          if (i === props.waypointList.length - 1) {
            wayPointString = `${i + 1} 0 0 16 0 0 0 0 ${props.waypointList[i].lat.toFixed(9)} ${props.waypointList[i].lng.toFixed(9)} ${
              props.waypointList[i].alt
            } 1`
          } else {
            wayPointString = `${i + 1} 0 0 16 0 0 0 0 ${props.waypointList[i].lat.toFixed(9)} ${props.waypointList[i].lng.toFixed(9)} ${
              props.waypointList[i].alt
            } 1\n`
          }
          exportString += wayPointString
        }
      }
      const payload = {}
      payload.component = proxy.$modalAlertNames.DRONELOGS
      payload.wpText = exportString
      payload.droneName = droneName
      payload.waypointList = props.waypointList
      payload.callBackCancel = proxy => {
        proxy.$emit('close', proxy.$props.index)
      }
      await dispatch('layout/pushModalAlertComponent', payload)
    }

    const fnHudClick = async drone => {
      proxy.$emit('fnHudClick', drone.id)
    }

    const addMissionDrone = val => {
      state.optionTab = !val
      if (state.optionTab) props.mainState.droneArr.push(props.ctrlDrone)
      else props.mainState.droneArr = props.mainState.droneArr.filter(drone => drone.name !== props.ctrlDrone.name)
      dispatch('drone/setMissionDrone', props.mainState.droneArr)

      proxy.$emit('update', state.optionTab, props.ctrlDrone)
    }

    const getAllGeofence = async () => {
      const result = await proxy.$GeofenceUtils.getAllGeofence()
    }

    const removeDronePath = async removeDrone => {
      const main = await proxy.$store.getters['layout/getMainComponent']
      main.state.ctrlDroneList.find(drone => {
        if (drone.id == removeDrone.id) drone.flightPath.length = 0
      })
    }

    const fnTabShow = index => {
      const _tag = document.getElementById(index).className === 'select-tab on'
      if (state.showMode[index]) {
        state.showMode[index] = _tag
      } else {
        state.showMode[index] = !_tag
      }
    }

    const fnSelect = (id, idx) => {
      state.selectedVersion = 'v' + idx
      state.showMode[id] = false
    }

    const lxLogin = async drone => {
      // 회원 정보 노출
      await modalPopUp(modalAlertNames.LXLOGIN, () => {}, drone)
    }

    const onClickVideo = () => {
      state.isVideo = !state.isVideo
    }

    return {
      ...toRefs(state),
      fnLogs,
      addMissionDrone,
      fnHudClick,
      zoomToWp,
      zoomToHomePosition,
      getAllGeofence,
      removeDronePath,
      fnTabShow,
      fnSelect,
      lxLogin,
      updateMavVersion,
      onClickVideo
    }
  }
}
</script>

<style scoped>
.inactive_hud {
  z-index: -100;
  background-color: rgba(58, 64, 68, 0.5);
}
.svgSetting {
  margin-right: 4px;
  width: 14px;
  height: 16px;
  background-size: cover;
  background-repeat: no-repeat;
}

.loader {
  font-size: 10px;
  margin: 100px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
