<template>
  <td style="width: 262px">
    <div v-if="droneList[droneIdx]" style="background: rgba(58, 64, 68, 0.8); border-radius: 16px">
      <div class="component-name" style="height: 47px;">
        <div class="drone-name-box">
          <div class="drone-name">{{ droneList[droneIdx] }}</div>
        </div>
        <!-- <i class="i-set-close" @click="onClickImageView(droneList[droneIdx])"></i> -->
      </div>
      <div>
        <img
          :id="`${droneList[droneIdx]}_imgt`"
          style="
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px; 
                width: 100%;
                object-fit: cover;"
          :style="isHudShow ? `height: ${imgHeight}px` : 'height: 532px'"
          :src="require(`@/assets/static/images/${testImage}`)"
        />
      </div>
      <!-- 625 -->
    </div>
    <div v-else style="background: rgba(58, 64, 68, 0.8); border-radius: 16px">
      <div class="component-name" style="height: 47px;">
        <div class="drone-name-box">
          <div class="drone-name">대상 기체를 선택해 주세요.</div>
        </div>
      </div>
      <div>
        <img
          style="width: 100%; opacity: 0.5; border-bottom-left-radius: 16px;border-bottom-right-radius: 16px;"
          :style="`height: ${imgHeight+34}px`"
          :src="require(`@/assets/static/images/${noneImage}`)"
        />
      </div>
    </div>
  </td>
</template>

<script>
import { reactive, toRefs, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'image-panel-td',
  props: {
    droneIdx: {},
    isHudShow: {},
    imgHeight: {}
  },
  setup() {
    const { dispatch, getters } = useStore()
    const state = reactive({
      droneList: computed(() => getters['drone/getImageList']),
      noneImage: 'no_image.jpg',
      testImage: 'test.png',
    })

    onMounted(() => {})

    const onClickImageView = async droneName => {
      await dispatch('drone/setImageList', droneName)
    }

    return {
      ...toRefs(state),
      onClickImageView,
    }
  }
}
</script>
